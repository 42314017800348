import { memo } from 'react';
import { useRouter } from 'next/router';
import { Box, Typography } from '@mui/material';
import Image from 'next/image';

import theme from 'src/style-system/theme';
import CuminButton from '@components/Button';
import { AppRoutes } from '@utils/appRoutes';

import { TasteSectionContent } from '../constant';
import { useStyles } from './useStyles';

interface ITasteSection {
  stickyBtnbgColor: boolean;
  countryCode: string;
}

const TasteSection = ({
  stickyBtnbgColor: stickyBtnbgColor,
  countryCode,
}: ITasteSection) => {
  const classes = useStyles();

  const router = useRouter();

  const handleClick = () => {
    router.push(`/${countryCode}${AppRoutes.ORDER_STEP_TWO}`);
  };

  return (
    <Box>
      <Box className={classes.mainContainer}>
        {TasteSectionContent.map((item) => (
          <Box
            key={item.id}
            bgcolor={item.bgColor}
            className={classes.itemContainer}
            sx={{
              flexDirection: 'column-reverse',
              [theme.breakpoints.up(800)]: {
                //flexDirection: item.id % 2 === 0 ? 'row-reverse' : 'row',
                flexDirection: 'row',
              },
            }}
          >
            <Box className={classes.textWrapper}>
              <Box className={classes.desktopTitleWrapper}>
                <Typography
                  variant="h1"
                  color={item.titleColor}
                  className={classes.title}
                >
                  {item.title}
                </Typography>
              </Box>
              <Box className={classes.mobileTitleWrapper}>
                <Typography
                  variant="h1"
                  color={item.titleColor}
                  sx={{ textAlign: { xs: 'center' } }}
                >
                  A Taste
                </Typography>
                <Typography
                  variant="h1"
                  color={item.titleColor}
                  sx={{ textAlign: { xs: 'center' } }}
                >
                  of {item.city}
                </Typography>
              </Box>
              <Typography
                variant="body2"
                color={item.textColor}
                sx={{ marginBottom: '30px' }}
                className={classes.description}
              >
                {item.description}
              </Typography>

              <Box className={classes.viewMenuBtnInDesktop}>
                <CuminButton
                  color={item.btnColor}
                  variant="contained"
                  textColor={item.btnTextColor}
                  borderRadius="24"
                  width="200px"
                  height="48"
                  hoveredColor={item.btnHoveredColor}
                  onClick={handleClick}
                >
                  Build Your Box
                </CuminButton>
              </Box>
            </Box>
            <Box className={classes.imageWrapper}>
              <Image
                src={item.icon}
                alt={item.alt}
                objectFit="contain"
                layout="fill"
                placeholder="blur"
                blurDataURL="https://d222i9ppxs2fqe.cloudfront.net/paneerButterMasala_blur.png" // later remove this hardcoded url
              />
            </Box>
          </Box>
        ))}
      </Box>

      <Box
        className={classes.viewMenuBtnInMobile}
        sx={{
          padding: stickyBtnbgColor ? '12px 0' : '35px 0',
          background: stickyBtnbgColor
            ? theme.palette.garamMasala.main
            : 'linear-gradient(180deg, rgba(29, 28, 27, 0) 0%, rgba(29, 28, 27, 0) 0.01%, rgba(29, 28, 27, 0.5) 100%)',
        }}
      >
        <CuminButton
          color="cinnamon"
          variant="contained"
          textColor="whitePepper.main"
          borderRadius="24"
          width="200px"
          height="48"
          onClick={handleClick}
        >
          Build Your Box
        </CuminButton>
      </Box>
    </Box>
  );
};

export default memo(TasteSection);
