import { makeStyles } from '@mui/styles';
import { display } from '@mui/system';
import theme from 'src/style-system/theme';

export const useStyles = makeStyles({
  mainContainer: {
    padding: '10px 20px 100px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',

    [theme.breakpoints.up(800)]: {
      padding: '12px 50px 96px',
    },
    [theme.breakpoints.up(1300)]: {
      padding: '20px 50px 150px',
    },
    [theme.breakpoints.up('xl')]: {
      padding: '40px 85px 160px',
    },
  },

  titleWrapper: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',

    [theme.breakpoints.down(800)]: {
      maxWidth: '350px',
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '310px',
    },
    [theme.breakpoints.down(500)]: {
      maxWidth: '280px',
    },
  },

  priceTagWrapper: {
    position: 'absolute',
    right: '-50px',
    bottom: '-100px',
    transform: 'rotate(10deg)',
    [theme.breakpoints.up(500)]: {
      right: '-30px',
      bottom: '-100px',
    },

    [theme.breakpoints.up(800)]: {
      right: '220px',
      bottom: '-130px',
      top: 'auto',
    },

    [theme.breakpoints.up(1100)]: {
      right: '-150px',
      bottom: '30px',
    },
  },

  priceTag: {
    [theme.breakpoints.down('lg')]: {
      transform: 'scale(0.9)',
    },
    [theme.breakpoints.down('md')]: {
      transform: 'scale(0.7)',
    },
  },

  priceInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    inset: '0 0 0 0',
    bottom: '10px !important',
    left: '4px !important',
    [theme.breakpoints.up('lg')]: {
      bottom: '16px !important',
    },
  },

  button: {
    width: '200px !important',
    height: '48px !important',
    borderRadius: '24px !important',

    [theme.breakpoints.up('sm')]: {
      width: '250px !important',
      borderRadius: '60px !important',
    },

    // [theme.breakpoints.up(1300)]: {
    //   width: '425px !important',
    //   height: '76px !important',
    //   borderRadius: '55px !important',
    // },
  },
});
