import { makeStyles } from '@mui/styles';
import theme from 'src/style-system/theme';

export default makeStyles({
  maincontainer: {
    backgroundColor: theme.palette.cumin.main,
  },

  container: {
    maxWidth: '1700px',
    margin: '0 auto',
    position: 'relative',
    padding: '50px 35px',
    [theme.breakpoints.up('md')]: {
      padding: '50px 75px',
    },
  },

  itemscontainer: {
    marginTop: '50px',
    display: 'flex',
    columnGap: '16px',
    overflowX: 'auto',

    // to not display scroll bar
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '-ms-overflow-style': 'none' /* IE and Edge */,
    scrollbarWidth: 'none' /* Firefox */,
  },

  item: {
    display: 'flex',
  },

  image: {
    position: 'relative',
    width: '250px',
    height: '250px',
    borderRadius: '8px',
    overflowX: 'hidden',

    [theme.breakpoints.up('lg')]: {
      width: '300px',
      height: '300px',
    },

    [theme.breakpoints.up('xl')]: {
      width: '325px',
      height: '325px',
    },
  },

  backwardBtnWrapper: {
    position: 'absolute',
    top: '48%',
    left: '0px',
    zIndex: 10,

    [theme.breakpoints.up('md')]: {
      left: '45px',
    },
  },

  forwardBtnWrapper: {
    position: 'absolute',
    top: '48%',
    right: '0px',
    zIndex: 10,

    [theme.breakpoints.up('md')]: {
      right: '45px',
    },
  },

  btnWrapper: {
    marginTop: '48px',
    display: 'flex',
    columnGap: '16px',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
