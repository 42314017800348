import Image from 'next/image';
import { Box, Typography } from '@mui/material';

import { FeaturedInContent } from '../constant';
import { useStyles } from './useStyles';

const FeaturedIn = () => {
  const classes = useStyles();

  return (
    <Box bgcolor="cumin.main">
      <Box className={classes.container}>
        {FeaturedInContent?.map((item) => (
          <Box
            key={item.id}
            sx={{
              position: 'relative',
              flexShrink: 0,
            }}
          >
            <a href={item.url} target="_blank">
              <Image src={item.icon} alt={item.alt} objectFit="contain" />
            </a>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default FeaturedIn;
