import { memo } from 'react';
import Image from 'next/image';
import { CSSProperties } from '@mui/material/styles/createTypography';
import { Box } from '@mui/system';
import { Container, Typography } from '@mui/material';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import theme from 'src/style-system/theme';
import { reviewCarouselType } from '@utils/types';
import { animationduration } from '@utils/data';
import PraiseHands from '@public/assets/svg/praiseHands.svg';
import Verification from '@public/assets/svg/verification.svg';

import { useStyles } from './useStyles';

interface CarouselProps {
  carouselContent: reviewCarouselType[];
  bgColor?: string;
  textColor?: string;
}

const ReviewCarousel = ({
  carouselContent,
  bgColor,
  textColor,
}: CarouselProps) => {
  const classes = useStyles();
  const indicatorStyles: CSSProperties = {
    background: theme.palette.whitePepper.main,
    width: 10,
    height: 10,
    display: 'inline-block',
    margin: '0 8px',
    borderRadius: '50%',
    border: `1px solid ${theme.palette.cumin.main}`,
    cursor: 'pointer',
  };

  return (
    <>
      <Box bgcolor={bgColor || 'light2.main'}>
        <Container maxWidth="xl" disableGutters>
          <Carousel
            autoPlay
            swipeable={false}
            infiniteLoop
            interval={animationduration}
            showStatus={false}
            showThumbs={false}
            showArrows={false}
            renderIndicator={(onClickHandler, isSelected, index, label) => {
              if (isSelected) {
                return (
                  <li
                    style={{
                      ...indicatorStyles,
                      background: theme.palette.garamMasala.main,
                    }}
                    aria-label={`Selected: ${label} ${index + 1}`}
                    title={`Selected: ${label} ${index + 1}`}
                  />
                );
              }
              return (
                <li
                  style={indicatorStyles}
                  onClick={onClickHandler}
                  onKeyDown={onClickHandler}
                  value={index}
                  key={index}
                  role="button"
                  tabIndex={0}
                  title={`${label} ${index + 1}`}
                  aria-label={`${label} ${index + 1}`}
                />
              );
            }}
          >
            {carouselContent.map((content) => {
              return (
                <Box key={content.id} className={classes.mainContainer}>
                  <Box>
                    <Box className={classes.imgContainer}>
                      <Image
                        src={content.icon}
                        alt={content.alt}
                        objectFit="contain"
                        layout="fill"
                        placeholder={content.blurUrl ? 'blur' : 'empty'}
                        blurDataURL={content.blurUrl}
                      />
                      <Box className={classes.handSvg}>
                        <PraiseHands />
                      </Box>
                    </Box>
                    <Box className={classes.verification}>
                      <Verification />
                      <Typography variant="caption" color="dark3.main">
                        Verified Member
                      </Typography>
                    </Box>
                  </Box>
                  <Box className={classes.titleContainer}>
                    <Typography
                      variant="body1"
                      color={textColor || 'garamMasala.main'}
                      sx={{
                        textAlign: 'center',
                        [theme.breakpoints.up(780)]: {
                          textAlign: 'left',
                        },
                      }}
                    >
                      {content.title}
                    </Typography>
                    <Typography
                      variant="h1"
                      color="garamMasala.main"
                      className={classes.colon}
                    >
                      ”
                    </Typography>
                  </Box>
                </Box>
              );
            })}
          </Carousel>
        </Container>
      </Box>
    </>
  );
};

export default memo(ReviewCarousel);
