import { makeStyles } from '@mui/styles';
import theme from 'src/style-system/theme';

//breakpoints 760px

export const useStyles = makeStyles({
  mainContainer: {
    backgroundColor: theme.palette.whitePepper.main,
    padding: '32px 25px',
    [theme.breakpoints.up('sm')]: {
      padding: '32px 48px',
    },
    [theme.breakpoints.up('md')]: {
      padding: '48px 60px',
    },
  },

  container: {
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'center',
    columnGap: '32px',
    [theme.breakpoints.up('lg')]: {
      columnGap: '75px',
    },

    [theme.breakpoints.down(760)]: {
      flexDirection: 'column',
      rowGap: '24px',
    },
  },

  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.up(760)]: {
      maxWidth: '220px',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '250px',
    },
  },

  serialNumber: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '35px',
    height: '35px',
    borderRadius: '50%',
    backgroundColor: theme.palette.turmeric.main,
    [theme.breakpoints.up('md')]: {
      width: '40px',
      height: '40px',
    },
  },

  img: {
    flex: 1,
    display: 'flex',
    alignItems: 'flex-end',
  },
});
