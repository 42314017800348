import { makeStyles } from '@mui/styles';
import theme from 'src/style-system/theme';

export const useStyles = makeStyles({
  mainContainer: {
    padding: '40px 43px 56px',

    [theme.breakpoints.up('sm')]: {
      padding: '40px 50px 56px',
    },

    [theme.breakpoints.up('md')]: {
      padding: '40px 35px 56px',
    },

    [theme.breakpoints.up('lg')]: {
      padding: '80px 75px',
    },

    [theme.breakpoints.up('xl')]: {
      padding: '80px 75px 140px',
    },
  },

  topContainer: {
    display: 'flex',
    flexDirection: 'column',

    [theme.breakpoints.up('md')]: {
      flexDirection: 'row-reverse',
      columnGap: '75px',
    },

    [theme.breakpoints.up('lg')]: {
      columnGap: '150px',
    },

    [theme.breakpoints.up('xl')]: {
      columnGap: '250px',
    },
  },

  logoWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '32px',
    // [theme.breakpoints.up('md')]: {
    //   display: 'none',
    // },
  },

  joinClubWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flexShrink: '0',
    [theme.breakpoints.up('md')]: {
      flexBasis: '340px',
    },
    [theme.breakpoints.up('xl')]: {
      flexBasis: '460px',
    },
  },

  inputwrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    columnGap: '25px',
    maxWidth: '400px',
    position: 'relative',
  },

  inputField: {
    fontSize: '24px',
    height: '32px',
    padding: '2px 40px 2px 5px',
    borderBottom: `1px solid ${theme.palette.cinnamon.main}`,
    outline: 'none',
    marginBottom: '15px',
    paddingBottom: '30px',
    width: '100%',
  },

  goWrapper: {
    flexShrink: '0',
    width: '33px',
    cursor: 'pointer',
    position: 'absolute',
    right: '0',
    bottom: '20px',
  },

  socialMediaiconWrapper: {
    marginTop: '30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    columnGap: '30px',

    [theme.breakpoints.up('sm')]: {
      justifyContent: 'flex-start',
    },
  },

  bottomContainer: {
    width: '100%',
    backgroundColor: theme.palette.garamMasala.dark,
    display: 'flex',
    justifyContent: 'space-around',
    columnGap: '5px',
    padding: '15px',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
});
