import React, { useRef } from 'react';
import { Box, Modal as MuiModal } from '@mui/material';

import useClickOutside from '@utils/useClickOutside';

import { useStyles } from './useStyles';

interface IModalComponent {
  open: boolean;
  handleClose: (open: boolean) => void;
  children: React.ReactNode;
  bgColorOpacity?: number;
  translateY?: string;
  isNoWidthSet?: boolean;
  fullOnMobile?: boolean;
}

const ModalComponent = ({
  open,
  handleClose,
  children,
  bgColorOpacity = 0.5,
  translateY = '-50%',
  isNoWidthSet = false,
  fullOnMobile = false,
}: IModalComponent) => {
  const getClasses = useStyles({
    bgColorOpacity,
    translateY,
    isNoWidthSet,
    fullOnMobile,
  });
  const classes = getClasses();
  const ref = useRef<HTMLDivElement>(null);

  const closeModal = () => {
    handleClose(false);
  };

  useClickOutside(ref, () => {
    closeModal();
  });

  return (
    <MuiModal
      open={open}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className={classes.container}>
        <Box className={classes.mainContainer} ref={ref}>
          {children}
        </Box>
      </Box>
    </MuiModal>
  );
};

export default ModalComponent;
