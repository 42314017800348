import { useEffect } from 'react';

import { useGetPromoCodeDetailsMutation } from 'commonModules/account';
import { promoCodeDetailsVar, updatePromoCode } from 'store/promoCode';
import { snackbarInfo } from 'store/utils';

interface TupdatePromoCodeDetails {
  promoCode: string;
  countryCode?: string;
  planId?: number;
}

const useUpdateBannerCopyWithPromo = (shouldFiredABTest?: boolean) => {
  const { getPromoCodeDetails } = useGetPromoCodeDetailsMutation();

  const updatePromoCodeDetails = async ({
    promoCode,
    countryCode,
    planId,
  }: TupdatePromoCodeDetails) => {
    updatePromoCode(promoCode);
    if (countryCode && promoCode) {
      try {
        const { data } = await getPromoCodeDetails({
          variables: {
            input: {
              referralCode: promoCode,
              countryCode,
              planId: planId || '',
            },
          },
        });

        if (data?.getPromoCodeDetails) {
          promoCodeDetailsVar({
            ...data.getPromoCodeDetails,
            promoCode: promoCode,
          });
        }
      } catch (error: any) {
        snackbarInfo({
          message: error?.message,
          type: 'error',
        });
      }
    }
  };

  return { updatePromoCodeDetails };
};

export default useUpdateBannerCopyWithPromo;
