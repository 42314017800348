import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: any) => ({
  mainContainer: {
    width: '90vw',
    borderRadius: '25px',
    backgroundColor: '#FBFAFA',
    padding: '40px 25px 30px',
    position: 'relative',

    [theme.breakpoints.up(700)]: {
      padding: '50px 35px',
      width: '650px',
    },
  },

  closeBtnWrapper: {
    position: 'absolute',
    right: '25px',
    top: '15px',
  },

  btnWrapper: {
    marginTop: '20px',
    display: 'flex',
    justifyContent: 'center',
    columnGap: '24px',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
      rowGap: '10px',
    },
  },
}));
