import React from 'react';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { Box } from '@mui/material';

interface IArrowTooltips {
  title: string;
  children: React.ReactElement;
}

const ArrowTooltips = ({ title, children }: IArrowTooltips) => {
  return (
    <Tooltip title={title} arrow sx={{ cursor: 'pointer', display: 'flex' }}>
      <Box component="span">{children}</Box>
    </Tooltip>
  );
};

export default ArrowTooltips;
