import { makeStyles } from '@mui/styles';
import { display } from '@mui/system';
import theme from 'src/style-system/theme';

export const useStyles = makeStyles({
  '@global': {
    '.control-dots': {
      //margin: '10px 0 25px !important',
    },
  },

  mainContainer: {
    padding: '46px 40px 60px',
    display: 'flex',
    flexDirection: 'column-reverse',
    rowGap: '30px',
    alignItems: 'center',
    justifyContent: 'center',

    [theme.breakpoints.up('sm')]: {
      columnGap: '50px',
    },

    [theme.breakpoints.up(780)]: {
      flexDirection: 'row',
    },

    [theme.breakpoints.up('md')]: {
      padding: '60px 30px 40px',
    },

    [theme.breakpoints.up('lg')]: {
      padding: '60px 100px 40px',
      columnGap: '120px',
    },
  },

  imgContainer: {
    flexShrink: '0',
    position: 'relative',
    width: '195px',
    height: '180px',
    [theme.breakpoints.up('md')]: {
      width: '220px',
      height: '250px',
    },
  },

  handSvg: {
    position: 'absolute',
    right: '-25px',
    bottom: '-5px',
  },

  titleContainer: {
    position: 'relative',
    textAlign: 'center',
    maxWidth: '490px',

    // [theme.breakpoints.up('sm')]: {
    //   maxWidth: '500px',
    // },

    [theme.breakpoints.up(780)]: {
      textAlign: 'left',
    },

    // [theme.breakpoints.up('md')]: {
    //   maxWidth: '750px',
    // },

    // [theme.breakpoints.up('lg')]: {
    //   width: '885px',
    // },
  },

  verification: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    columnGap: '4px',
  },

  colon: {
    position: 'absolute',
    right: '-5px',
    bottom: '-35px',
    [theme.breakpoints.up('lg')]: {
      //top: '40px',
    },

    [theme.breakpoints.down(780)]: {
      display: 'none',
    },
  },
});
