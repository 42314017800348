import InView from 'react-intersection-observer';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { Box, Divider, Typography } from '@mui/material';
import { useReactiveVar } from '@apollo/client';

import {
  countryCodeForEventVar,
  homePageAbTestVar,
  isABTestToFiredVar,
  isComeFromRedirectVar,
  isNewOrderFlowVar,
  planAbTestNameVar,
  utmQueryVar,
} from 'store/utils';
import theme from 'src/style-system/theme';
import Footer from '@components/Footer';
import {
  AUTO_PROMO_CODE,
  HOME_PAGE_BG_IMAGE_URL_DESKTOP,
  HOME_PAGE_BG_IMAGE_URL_MINI_TABLET,
  HOME_PAGE_BG_IMAGE_URL_MOBILE,
  HOME_PAGE_BG_IMAGE_URL_TABLET,
  HYDERABADI_BIRYANI,
  MALABAR_VEG_KURMA,
  MASALA_BHINDI,
  METHI_MATAR_MALAI,
  PANEER_BHURJI,
} from '@utils/data';
import AnnouncementBanner from '@components/AnnouncementBanner';
import useAuth from '@utils/useAuth';
import useUpdateBannerCopyWithPromo from '@utils/useUpdateBannerCopyWithPromo';
import RotatingDish from '@components/RotatingDish';
import PlanBanner from '@components/PlanBanner';
import OneLineDetail from '@components/OneLineDetail';
import { ABC_TEST_PROMO } from '@utils/initialData';
import { generateRandomNumber } from '@utils/helpers';
import { useElevar } from '@utils/elevar';

import HeroSection from './HeroSection';
import ReviewCarousel from './ReviewCarousel';
import GiftSection from './GiftSection';
import TasteSection from './TasteSection';
import HomeHeader from './HomeHeader';
import FeaturedIn from './FeaturedIn';
import WhyJoin from './WhyJoin';
import HowToPrepare from './HowToPrepare';
import RedirectToIndiaModal from './RedirectToIndiaModal';
import InstagramSection from './InstagramSection';
import { OneLineDetailContent, ReviewCarouselContent } from './constant';
import SaucesSection from './SaucesSection';

const Home = () => {
  const [stickyBtnbgColor, setStickyBtnBgColor] = useState<boolean>(false);
  const [detectedCountryCode, setDetectedCountryCode] = useState<string>('');

  const router = useRouter();

  const { elevarUserDataEvent } = useElevar();

  const { new_order_flow, landing_page_variant } = router.query;

  const { detectedCountryCode: countryCode, authState } = useAuth();

  const { updatePromoCodeDetails } = useUpdateBannerCopyWithPromo();
  const isPlanAbTestFired = useReactiveVar(homePageAbTestVar);

  useEffect(() => {
    if (countryCode) {
      setDetectedCountryCode(countryCode);
    }
  }, [countryCode]);

  // elevar dl_user_data
  useEffect(() => {
    if (authState) {
      setTimeout(() => {
        elevarUserDataEvent();
      }, 500);
    }
  }, [authState]);

  useEffect(() => {
    //@ts-ignore
    window?.dataLayer.push({ event: 'home' });

    //elevar
    //@ts-ignore
    window.ElevarInvalidateContext && window.ElevarInvalidateContext?.();

    isComeFromRedirectVar(false);

    if (Object.keys(router?.query).length) {
      utmQueryVar(router?.query);
    }
  }, []);

  useEffect(() => {
    if (new_order_flow) {
      isNewOrderFlowVar(true);
    }
  }, [new_order_flow]);

  useEffect(() => {
    // ABC test
    if (landing_page_variant) {
      if (!isPlanAbTestFired) {
        //@ts-ignore
        window?.dataLayer.push({
          event: 'home_page_event',
          ab_test_variant: landing_page_variant,
        });
        homePageAbTestVar(true);
      }
      planAbTestNameVar(landing_page_variant as string);
      isABTestToFiredVar(true);
    }

    if (router?.query?.promo && countryCode) {
      // to update banner as well store promo to global store
      updatePromoCodeDetails({
        promoCode: router.query.promo as string,
        countryCode,
      });
    }
  }, [router?.query, countryCode, landing_page_variant]);

  const onVisible = (inView: boolean) => {
    if (inView) {
      setStickyBtnBgColor(true);
    } else {
      stickyBtnbgColor && setStickyBtnBgColor(false);
    }
  };

  return (
    <>
      {/* <ExitIntent /> */}
      <RedirectToIndiaModal />
      <AnnouncementBanner />
      <HomeHeader countryCode={detectedCountryCode} />
      <Box
        sx={{
          position: 'relative',
          backgroundImage: `url(${HOME_PAGE_BG_IMAGE_URL_MOBILE})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          [theme.breakpoints.up('sm')]: {
            backgroundImage: `url(${HOME_PAGE_BG_IMAGE_URL_MINI_TABLET})`,
          },
          [theme.breakpoints.up(800)]: {
            backgroundImage: `url(${HOME_PAGE_BG_IMAGE_URL_TABLET})`,
          },
          [theme.breakpoints.up(1300)]: {
            backgroundImage: `url(${HOME_PAGE_BG_IMAGE_URL_DESKTOP})`,
          },
        }}
      >
        <Box
          sx={{ background: 'rgba(29,28,27,0.1)' }}
          paddingTop={{ xs: '78px', md: '98px' }}
        >
          <HeroSection countryCode={detectedCountryCode} />
        </Box>
      </Box>
      <OneLineDetail data={OneLineDetailContent} />
      <FeaturedIn />
      <Box sx={{ position: 'relative' }}>
        <Box sx={{ position: 'relative', overflow: 'hidden' }}>
          <WhyJoin />
          <Box
            sx={{
              position: 'absolute',
              top: '1%',
              right: '-200px',
              [theme.breakpoints.down('lg')]: { display: 'none' },
            }}
          >
            <RotatingDish icon={MALABAR_VEG_KURMA} alt="MALABAR_VEG_KURMA" />
          </Box>
          <Box
            sx={{
              position: 'absolute',
              top: '30%',
              left: '-200px',
              [theme.breakpoints.down('lg')]: { display: 'none' },
            }}
          >
            <RotatingDish icon={PANEER_BHURJI} alt="PANEER_BHURJI" />
          </Box>
          <Box
            sx={{
              position: 'absolute',
              top: '70%',
              right: '-200px',
              [theme.breakpoints.down('lg')]: { display: 'none' },
            }}
          >
            <RotatingDish icon={HYDERABADI_BIRYANI} alt="MASALA_BHINDI" />
          </Box>
        </Box>

        <Box
          sx={{
            position: 'sticky',
            bottom: '0px',
            boxShadow: '0px -10px 20px rgba(0, 0, 0, 0.25)',
            zIndex: 11,
          }}
        >
          <PlanBanner
            isAbtestFired={true}
            isNewPlanBanner={true}
            isTitleOnMobile={false}
            isSmallPadding
          />
        </Box>
      </Box>
      <Box sx={{ position: 'relative', overflow: 'hidden' }}>
        <HowToPrepare />
        <InstagramSection />
        <SaucesSection countryCode={detectedCountryCode} />
        <GiftSection />
        <ReviewCarousel carouselContent={ReviewCarouselContent} />

        <Box
          sx={{
            position: 'absolute',
            bottom: '30%',
            right: '-200px',
            [theme.breakpoints.down('lg')]: { display: 'none' },
          }}
        >
          <RotatingDish icon={PANEER_BHURJI} alt="PANEER_BHURJI" />
        </Box>

        <Box
          sx={{
            position: 'absolute',
            top: '10%',
            left: '-200px',
            [theme.breakpoints.down('lg')]: { display: 'none' },
          }}
        >
          <RotatingDish icon={METHI_MATAR_MALAI} alt="METHI_MATAR_MALAI" />
        </Box>
        <Box
          sx={{
            position: 'absolute',
            bottom: '10%',
            left: '-200px',
            [theme.breakpoints.down('lg')]: { display: 'none' },
          }}
        >
          <RotatingDish icon={MASALA_BHINDI} alt="MASALA_BHINDI" />
        </Box>
      </Box>
      <TasteSection
        stickyBtnbgColor={stickyBtnbgColor}
        countryCode={detectedCountryCode}
      />
      <Divider
        sx={{
          height: '2px',
          backgroundColor: 'cinnamon.main',
        }}
      />
      <InView threshold={0.1} onChange={(inView: boolean) => onVisible(inView)}>
        <Footer />
      </InView>
    </>
  );
};

export default Home;
