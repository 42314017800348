import { memo } from 'react';
import { useRouter } from 'next/router';
import { Typography, Box } from '@mui/material';

import theme from 'src/style-system/theme';
import { isABTestToFiredVar } from 'store/utils';
import { AppRoutes } from '@utils/appRoutes';
import useAuth from '@utils/useAuth';
import Button from '@components/Button';

import useStyles from './useStyles';

interface IPlanBanner {
  isTitleOnMobile?: boolean;
  isSmallPadding?: boolean;
  isAbtestFired?: boolean;
  isNewPlanBanner?: boolean;
}

const PlanBanner = ({
  isTitleOnMobile = true,
  isSmallPadding = false,
  isAbtestFired = false,
  isNewPlanBanner = false,
}: IPlanBanner) => {
  const classes = useStyles();
  const router = useRouter();
  const { authState, detectedCountryCode: countryCode } = useAuth();

  const handleClick = () => {
    router.push(`/${countryCode}${AppRoutes.ORDER_STEP_TWO}`);
  };

  return (
    <Box
      className={classes.container}
      bgcolor={isNewPlanBanner ? 'garamMasala.main' : 'cinnamon.main'}
      sx={{
        padding: isTitleOnMobile ? '40px 25px' : '15px 25px',
        [theme.breakpoints.up(720)]: {
          padding: isSmallPadding ? '15px 25px' : '30px 25px',
        },
      }}
    >
      <Typography
        variant={isNewPlanBanner ? 'h2' : 'body1'}
        color={isNewPlanBanner ? 'light1.main' : 'whitePepper.main'}
        align="center"
        sx={{
          [theme.breakpoints.down(750)]: {
            display: isTitleOnMobile ? 'block' : 'none',
          },
        }}
      >
        Let’s get you something delicious!
      </Typography>
      <Button
        variant="contained"
        color="turmeric"
        textColor={isNewPlanBanner ? 'dark3.main' : 'garamMasala.main'}
        width="200px"
        height="50"
        borderRadius="24"
        onClick={handleClick}
      >
        Build Your Box
      </Button>
    </Box>
  );
};

export default memo(PlanBanner);
