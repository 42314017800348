import { memo } from 'react';
import { Box, Typography } from '@mui/material';
import { useRouter } from 'next/router';

import theme from 'src/style-system/theme';
import { isABTestToFiredVar } from 'store/utils';
import { usePersistentPromoCodeDetails } from 'store/promoCode';
import CuminButton from '@components/Button';
import { AppRoutes } from '@utils/appRoutes';
import useAuth from '@utils/useAuth';
import { getPriceAfterDiscount } from '@utils/helpers';
import { countryStartingPriceMapping } from '@utils/constant/countryList';

import HomePagePriceTag from '@public/assets/svg/HomePagePriceTag.svg';

import { useStyles } from './useStyles';

interface IHeroSection {
  countryCode: string;
}

const HeroSection = ({  countryCode }: IHeroSection) => {
  const router = useRouter();
  const classes = useStyles();

  const { authState } = useAuth();
  const promoCodeDetails = usePersistentPromoCodeDetails();

  const { isPromoValid, amountType, amount } = promoCodeDetails || {};

  const handleClick = () => {
    router.push(`/${countryCode}${AppRoutes.ORDER_STEP_TWO}`);
  };

  return (
    <Box className={classes.mainContainer}>
      <Box className={classes.titleWrapper}>
        {countryCode ? (
          <Box className={classes.priceTagWrapper}>
            <HomePagePriceTag className={classes.priceTag} />
            <Box className={classes.priceInfo}>
              <Typography
                variant="caption"
                align="center"
                color="whitePepper.main"
              >
                starts at
              </Typography>
              {(isPromoValid && amountType === 'ABSOLUTE') ||
              (!isPromoValid && amountType) ||
              (isPromoValid &&
                (amountType === 'FREE_MEALS' ||
                  amountType === 'FREE_GIFTS')) ? null : (
                <Typography
                  variant="caption"
                  align="center"
                  color="whitePepper.main"
                  sx={{ textDecoration: 'line-through' }}
                >
                  {countryStartingPriceMapping[countryCode]?.currencySymbol +
                    countryStartingPriceMapping[countryCode]?.startingPrice}
                </Typography>
              )}
              <Typography variant="body2" color="turmeric.main" align="center">
                {countryStartingPriceMapping[countryCode]?.currencySymbol +
                  getPriceAfterDiscount({
                    price:
                      countryStartingPriceMapping[countryCode]?.startingPrice *
                      100,
                    amount:
                      !isPromoValid && amountType
                        ? 0
                        : isPromoValid
                        ? amount
                        : '15',
                    amountType: amountType,
                  }).toFixed(2)}
              </Typography>
              <Typography
                variant="caption"
                align="center"
                color="whitePepper.main"
              >
                per meal!
              </Typography>
            </Box>
          </Box>
        ) : null}
        <Typography variant="h1" align="left" color="garamMasala.main">
          5-Minute Meal Kits. Delivered.
        </Typography>

        <Typography
          variant="body2"
          align="left"
          color="garamMasala.main"
          sx={{
            margin: '10px 0 20px',
          }}
        >
          30+ dishes curated by expert Indian chefs for your everyday meals
        </Typography>
      </Box>
      <CuminButton
        color="cinnamon"
        variant="contained"
        textColor="whitePepper.main"
        className={classes.button}
        onClick={handleClick}
      >
        Build Your Box
      </CuminButton>
    </Box>
  );
};

export default memo(HeroSection);
