import { makeStyles } from '@mui/styles';
import { alpha } from '@mui/material';
import theme from 'src/style-system/theme';

export const useStyles = ({
  bgColorOpacity,
  translateY,
  isNoWidthSet,
  fullOnMobile,
}: any) => {
  return makeStyles({
    container: {
      height: '100vh',
      width: '100%',
      backgroundColor: alpha(theme.palette.cinnamon.main, bgColorOpacity),
      overflowY: 'auto',
      zIndex: 1000,
    },

    mainContainer: {
      position: 'absolute' as 'absolute',
      top: translateY !== '-50%' ? '0px' : '50%',
      left: '50%',
      transform: `translate(-50%, ${translateY})`,
      backgroundColor: theme.palette.whitePepper.main,
      borderRadius: '25px',

      [theme.breakpoints.down('sm')]: {
        width: isNoWidthSet ? 'auto' : fullOnMobile ? '100%' : '90%',
      },
    },

    closeIcon: {
      position: 'absolute',
      top: '25px',
      right: '30px',
      color: theme.palette.cinnamon.main,
      zIndex: 10,
      transform: 'scale(1.7)',
      cursor: 'pointer',
    },
  });
};
