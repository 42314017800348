import { makeStyles } from '@mui/styles';
import { display } from '@mui/system';
import theme from 'src/style-system/theme';

export const useStyles = makeStyles({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },

  itemContainer: {
    padding: '55px 25px',
    width: '100%',
    display: 'flex',

    rowGap: '30px',
    alignItems: 'center',
    justifyContent: 'center',

    [theme.breakpoints.up(800)]: {
      padding: '55px 50px',
      columnGap: '75px',
    },

    [theme.breakpoints.up('lg')]: {
      padding: '55px 75px',
      columnGap: '125px',
    },
  },

  textWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: '450px',

    [theme.breakpoints.up(800)]: {
      alignItems: 'flex-start',
      maxWidth: '450px',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '675px',
    },
  },

  desktopTitleWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },

  mobileTitleWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '20px',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },

  title: {
    marginBottom: '20px',
    textAlign: 'center',
    [theme.breakpoints.up(800)]: {
      textAlign: 'left',
    },
  },

  description: {
    textAlign: 'justify',
    [theme.breakpoints.up(800)]: {
      textAlign: 'left',
    },
  },

  imageWrapper: {
    flexShrink: '0',
    position: 'relative',
    width: '250px',
    height: '250px',

    [theme.breakpoints.up('lg')]: {
      width: '385px',
      height: '385px',
    },
  },

  viewMenuBtnInDesktop: {
    [theme.breakpoints.down(800)]: {
      display: 'none',
    },
  },

  viewMenuBtnInMobile: {
    zIndex: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'sticky',
    bottom: '0',
    width: '100%',
    [theme.breakpoints.up(800)]: {
      display: 'none',
    },
  },
});
