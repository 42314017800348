import Image from 'next/image';
import { Box, Typography } from '@mui/material';

import { HowToPrepageContent } from '../constant';
import { useStyles } from './useStyles';

const HowToPrepare = () => {
  const classes = useStyles();

  return (
    <Box className={classes.mainContainer}>
      <Typography variant="h1" color="garamMasala.main" align="center">
        How To Prepare
      </Typography>
      <Typography
        variant="body2"
        color="garamMasala.main"
        align="center"
        sx={{
          maxWidth: '560px',
          margin: '16px auto 48px',
        }}
      >
        The basic steps 1-2-3 are common across dishes and most dishes are also
        microwaveable. Specific instructions provided on the back of each pack
      </Typography>

      <Box className={classes.container}>
        {HowToPrepageContent.map((item) => (
          <Box key={item.id} className={classes.item}>
            <Typography
              variant="body1"
              color="garamMasala.main"
              align="center"
              mb="16px"
              className={classes.serialNumber}
            >
              {item.id}
            </Typography>
            <Box className={classes.img}>
              <Image src={item.icon} alt={item.title} objectFit="contain" />
            </Box>
            <Typography
              variant="h4"
              color="dark3.main"
              align="center"
              m="16px 0 8px"
            >
              {item.title}
            </Typography>
            <Typography variant="body2" color="dark3.main" align="center">
              {item.description}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default HowToPrepare;
