import { makeStyles } from '@mui/styles';
import theme from 'src/style-system/theme';

export default makeStyles({
  container: {
    background: theme.palette.whitePepper.main,
    padding: '50px 25px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',

    [theme.breakpoints.up('sm')]: {
      padding: '50px',
    },

    [theme.breakpoints.up('lg')]: {
      padding: '50px 75px',
    },
  },

  description: {
    maxWidth: '800px',
    margin: '20px auto 30px',
  },

  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    rowGap: '50px',

    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'baseline',
      columnGap: '75px',
    },

    [theme.breakpoints.up('md')]: {
      columnGap: '150px',
    },
  },

  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },

  imgWrapper: {
    position: 'relative',
  },

  cuminBtn: {
    marginTop: '20px',
    [theme.breakpoints.up('lg')]: {
      marginTop: '30px',
    },
  },
});
