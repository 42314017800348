import { makeStyles } from '@mui/styles';
import theme from 'src/style-system/theme';

export const useStyles = makeStyles({
  mainContainer: {
    backgroundColor: theme.palette.whitePepper.main,
    padding: '40px 25px 48px',
    [theme.breakpoints.up('sm')]: {
      padding: '48px 50px 112px',
    },
  },

  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    rowGap: '48px',
  },

  itemContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    columnGap: '24px',
    [theme.breakpoints.up('md')]: {
      columnGap: '48px',
    },
  },

  textContainer: {
    maxWidth: '430px',
  },

  imgWrapper: {
    overflow: 'hidden',
    borderRadius: '20px',
    flexShrink: 0,
    position: 'relative',
    width: '300px',
    height: '170px',
    [theme.breakpoints.up('md')]: {
      width: '400px',
      height: '230px',
    },
  },
});
