import { useEffect } from 'react';
import { createApolloClient } from '../graphql';
import type { NextPage } from 'next';
import { GET_SERVICEABLE_COUNTRIES } from 'src/http/country';
import HomePage from '../containers/HomePage';
import Head from 'next/head';
import { useRouter } from 'next/router';

const Home: NextPage = () => {
  const router = useRouter();

  useEffect(() => {
    router.prefetch('/order/step-1');
  }, []);

  return (
    <>
      <Head>
        <title>
          Authentic Indian Food Meal Kits Delivered | The Cumin Club | $4.99 Per
          Meal
        </title>
      </Head>
      <HomePage />
    </>
  );
};

// This gets called on every request
// export async function getStaticProps() {
//   const apolloClient = createApolloClient();
//   const fetchServiceableCountries = async () => {
//     try {
//       const res = await apolloClient.query({
//         query: GET_SERVICEABLE_COUNTRIES,
//       });
//       return res;
//     } catch (error) {
//       console.log(error);
//       return { error };
//     }
//   };
//   await fetchServiceableCountries();
//   return { props: { initialApolloState: apolloClient.cache.extract() } };
// }

export default Home;
