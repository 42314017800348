import Image from 'next/image';
import { Box, Typography } from '@mui/material';

import theme from 'src/style-system/theme';

import { WhyJoinContent } from '../constant';
import { useStyles } from './useStyles';

const WhyJoin = () => {
  const classes = useStyles();

  return (
    <Box className={classes.mainContainer}>
      <Typography
        variant="h1"
        color="garamMasala.main"
        align="center"
        mb="48px"
      >
        Authentic Indian, ready in a pinch
      </Typography>
      <Box className={classes.container}>
        {WhyJoinContent.map((item) => (
          <Box
            className={classes.itemContainer}
            key={item.id}
            sx={{
              flexDirection: item.id % 2 === 0 ? 'row-reverse' : 'row',
              [theme.breakpoints.down(760)]: {
                flexDirection: 'column',
                alignItems: 'center',
              },
            }}
          >
            <Box
              className={classes.textContainer}
              sx={{
                [theme.breakpoints.down(760)]: {
                  textAlign: 'center',
                },
              }}
            >
              <Typography variant="h4" color="dark3.main" m="16px 0 12px">
                {item.id + '. ' + item.title}
              </Typography>
              <Typography
                variant="body2"
                color="dark3.main"
                sx={{
                  lineHeight: '24px',
                  [theme.breakpoints.down(760)]: {
                    display: 'none',
                  },
                }}
              >
                {item.description}
              </Typography>
            </Box>
            <Box className={classes.imgWrapper}>
              <Image
                src={item.icon}
                alt={item.title}
                objectFit="contain"
                layout="fill"
              />
            </Box>
            <Typography
              variant="body2"
              color="dark3.main"
              align="center"
              sx={{
                lineHeight: '24px',
                marginTop: '16px',
                [theme.breakpoints.up(760)]: {
                  display: 'none',
                },
              }}
              className={classes.textContainer}
            >
              {item.description}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default WhyJoin;
