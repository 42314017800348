import { useEffect, useState, memo } from 'react';
import { Box } from '@mui/material';
import Image from 'next/image';

import { NextImageSrc } from '@utils/types';

interface IRotatingDish {
  icon: NextImageSrc;
  alt: string;
}

const RotatingDish = ({ icon, alt }: IRotatingDish) => {
  const [scrollProgress, setScrollProgress] = useState(0);

  const onDocumentScroll = () => {
    const scrollTop = window.pageYOffset; // how much the user has scrolled by
    setScrollProgress(scrollTop / 4);
  };

  useEffect(() => {
    document.addEventListener('scroll', onDocumentScroll);
    return () => {
      document.removeEventListener('scroll', onDocumentScroll);
    };
  }, []);

  return (
    <Box
      sx={{
        position: 'relative',
        width: '300px',
        height: '300px',
        transform: `rotate(${scrollProgress}deg)`,
      }}
    >
      <Image src={icon} alt={alt} objectFit="cover" layout="fill" />
    </Box>
  );
};

export default memo(RotatingDish);
