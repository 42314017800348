import { makeStyles } from '@mui/styles';
import theme from 'src/style-system/theme';

export const useStyles = makeStyles({
  container: {
    maxWidth: '1600px',
    margin: '0 auto',
    padding: '14px 50px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflowX: 'auto',
    columnGap: '24px',
    [theme.breakpoints.down(960)]: {
      justifyContent: 'flex-start',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '8px 50px',
    },
  },
});
