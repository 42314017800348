import { useEffect, useRef, useState } from 'react';
import Image from 'next/image';
import { Box, Typography } from '@mui/material';
import { usePrismicDocumentByUID } from '@prismicio/react';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import useStyles from './useStyles';
import CuminButton from '@components/Button';

const InstagramSection = () => {
  const [isBeholdShow, setIsBeholdShow] = useState<boolean>(true);
  const ref1 = useRef<any>(null);
  const ref = useRef<any>(null);

  const classes = useStyles();

  const [data, { state }] = usePrismicDocumentByUID(
    'home_page_instagram_grid',
    'instagram_post'
  );

  const { slices } = data?.data || {};
  const sliceData = slices?.length ? slices[0] : null;
  const { items } = sliceData || {};

  const translateForward = () => {
    if (ref?.current) {
      ref.current.scrollLeft += 250;
      ref.current.style.scrollBehavior = 'smooth';
    }
  };

  const translateBackward = () => {
    if (ref?.current) {
      ref.current.scrollLeft -= 250;
      ref.current.style.scrollBehavior = 'smooth';
    }
  };

  const openInNewTab = () => {
    const url = 'https://www.instagram.com/the_cumin_club/';
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  useEffect(() => {
    if (!ref1.current?.clientHeight) {
      setIsBeholdShow(false);
    }
    if (ref1.current?.clientHeight) {
      setIsBeholdShow(true);
    }
  }, [ref1.current?.clientHeight]);

  return state === 'loaded' ? (
    <Box className={classes.maincontainer}>
      <Box className={classes.container}>
        <Typography variant="h1" color="whitePepper.main" align="center">
          On the Grid
        </Typography>
        {!isBeholdShow ? (
          <>
            <Box className={classes.backwardBtnWrapper}>
              <ArrowBackIosNewIcon
                fontSize="large"
                sx={{ color: 'garamMasala.main', cursor: 'pointer' }}
                onClick={translateForward}
              />
            </Box>
            <Box ref={ref1} className={classes.itemscontainer}>
              {items?.map((item: any) => {
                const { instagram_post_img, instagram_post_link } = item || {};
                return (
                  <a target="_blank" href={instagram_post_link.url}>
                    <Box className={classes.image}>
                      <Image
                        src={instagram_post_img.url}
                        alt={instagram_post_img?.alt || 'Instagram_Post'}
                        objectFit="contain"
                        layout="fill"
                      />
                    </Box>
                  </a>
                );
              })}
            </Box>
            <Box className={classes.forwardBtnWrapper}>
              <ArrowForwardIosIcon
                fontSize="large"
                sx={{ color: 'garamMasala.main', cursor: 'pointer' }}
                onClick={translateBackward}
              />
            </Box>
          </>
        ) : (
          <Box ref={ref1} mt={{ xs: '20px', sm: '50px' }}>
            <div
              className={classes.itemscontainer}
              data-behold-id="wxQjkRQztdWwfSRM6Xlj"
            ></div>
          </Box>
        )}

        <Box mt={{ xs: '32px', md: '45px' }} textAlign="center">
          <CuminButton
            variant="contained"
            color="cinnamon"
            textColor="whitePepper.main"
            borderRadius="60"
            onClick={openInNewTab}
          >
            Follow us for more!
          </CuminButton>
        </Box>
      </Box>
    </Box>
  ) : null;
};

export default InstagramSection;
