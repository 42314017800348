import { makeStyles } from '@mui/styles';
import theme from 'src/style-system/theme';

export default makeStyles({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    rowGap: '20px',

    [theme.breakpoints.up(720)]: {
      flexDirection: 'row',
      columnGap: '30px',
    },
  },
});
