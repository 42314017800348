import Image from 'next/image';
import { useRouter } from 'next/router';
import { Box, Typography } from '@mui/material';

import theme from 'src/style-system/theme';
import CuminButton from '@components/Button';
import { AppRoutes } from '@utils/appRoutes';
import { sauce_section_img } from '../constant';

import useStyles from './useStyles';

const SaucesSection = ({ countryCode }: { countryCode: string }) => {
  const classes = useStyles();
  const router = useRouter();

  return (
    <Box bgcolor="garamMasala.main">
      <Box className={classes.mainContainer}>
        <Box className={classes.title}>
          <Typography variant="h4" color="turmeric.main">
            WE LAUNCHED
          </Typography>
          <Typography variant="h2" color="turmeric.main">
            Simmer Sauces
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '32px',
            [theme.breakpoints.up('md')]: {
              flexDirection: 'row-reverse',
              alignItems: 'center',
              columnGap: '36px',
            },
          }}
        >
          <Box className={classes.imgWrapper}>
            <Image
              src={sauce_section_img}
              alt={'sauce_section'}
              objectFit="contain"
              layout="fill"
            />
          </Box>
          <Box bgcolor="turmeric.main" className={classes.detailWrapper}>
            <Typography
              variant="body1"
              color="garamMasala.main"
              fontWeight={500}
            >
              5 min Simmer Sauces
            </Typography>
            <Typography
              variant="body1"
              color="garamMasala.main"
              fontWeight={500}
            >
              Serves 4
            </Typography>
            <Typography
              variant="body1"
              color="garamMasala.main"
              fontWeight={500}
            >
              Add up to 16oz of protein
            </Typography>
          </Box>
        </Box>
        <Typography variant="body1" color="light1.main" fontWeight={500}>
          The possibilities are endless.
        </Typography>
        <CuminButton
          variant="contained"
          color="cinnamon"
          textColor="light1.main"
          borderRadius="60"
          onClick={() =>
            router.push(`/${countryCode}${AppRoutes.ORDER_STEP_TWO}`)
          }
        >
          Sauce Up
        </CuminButton>
      </Box>
    </Box>
  );
};

export default SaucesSection;
