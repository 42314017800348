import { ApolloError, makeVar } from '@apollo/client';
import { ICountry, ICountryList } from '@utils/types';

export interface ICountryState {
  loading: boolean;
  error?: ApolloError | undefined;
  serviceableCountries: ICountry[];
}
export const countryStateVar = makeVar<ICountryState>({
  loading: false,
  error: undefined,
  serviceableCountries: [],
});

export const countryDetailVar = makeVar<any>([]);
