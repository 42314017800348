import { makeStyles } from '@mui/styles';
import theme from 'src/style-system/theme';

export const useStyles = makeStyles({
  mainContainer: {
    backgroundColor: theme.palette.garamMasala.main,
    padding: '8px 15px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      padding: '14px 50px',
    },
  },

  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    columnGap: '24px',
  },

  linkItems: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    columnGap: '48px',
    [theme.breakpoints.down(1040)]: {
      display: 'none',
    },
  },
});
