import { makeStyles } from '@mui/styles';
import theme from 'src/style-system/theme';

export default makeStyles({
  mainContainer: {
    padding: '52px 24px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    rowGap: '32px',
    maxWidth: '860px',
    margin: '0 auto',
    [theme.breakpoints.up('lg')]: {
      maxWidth: '910px',
    },
  },

  title: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '10px',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
    [theme.breakpoints.up('md')]: {
      justifyContent: 'center',
      width: '100%',
    },
  },

  imgWrapper: {
    position: 'relative',
    width: '330px',
    height: '140px',
    [theme.breakpoints.up('sm')]: {
      width: '480px',
      height: '210px',
    },
  },

  detailWrapper: {
    maxWidth: 'max-content',
    margin: '0 auto',
    padding: '24px 12px',
    borderRadius: '20px',
    display: 'flex',
    flexDirection: 'column',
    rowGap: '16px',
    alignItems: 'center',
  },
});
